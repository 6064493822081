.blog,
.post {
  min-height: calc(100dvh - $spacing-unit * 7);

  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.5;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
  }

  h3 {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  .center {
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    padding-bottom: 16px;
  }

  .small {
    font-size: 14px;
  }

  .xsmall {
    font-size: 12px;
  }

  .post-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 2px;
  }

  li {
    padding-bottom: 8px;
    padding-top: 4px;
  }

  strong {
    font-weight: 600;
  }

  a[href^="mailto:"] {
    font-weight: 600;
    color: darkblue;
  }

  /* Facebook button */
  .fa-facebook-official {
    color: #3b5998;
  }
  .fa-facebook-official:hover {
    background-color: #3b5998;
  }
  /* Twitter button */
  .fa-twitter {
    color: #55acee;
  }
  .fa-twitter:hover {
    background-color: #55acee;
  }
  /* Google-PLus button */
  .fa-google-plus {
    color: #dd4b39;
  }
  .fa-google-plus:hover {
    background-color: #dd4b39;
  }
  /* Pinterest button */
  .fa-pinterest-p {
    color: #cb2027;
  }
  .fa-pinterest-p:hover {
    background-color: #cb2027;
  }
  /* Tumblr button */
  .fa-tumblr {
    color: #32506d;
  }
  .fa-tumblr:hover {
    background-color: #32506d;
  }
  /* Reddit button */
  .fa-reddit-alien {
    color: #ff4500;
  }
  .fa-reddit-alien:hover {
    background-color: #ff4500;
  }
  /* LinkedIn button */
  .fa-linkedin {
    color: #007bb5;
  }
  .fa-linkedin:hover {
    background-color: #007bb5;
  }
  /* Email button */
  .fa-envelope {
    color: #444444;
  }
  .fa-envelope:hover {
    background-color: #444444;
  }

  .share-bar {
    padding-bottom: 16px;
  }

  .share-button {
    margin-right: 8px;
    border: 1px solid #d3d6d2;
    border-radius: 50%;
    padding: 10px;
    width: 16px;
    height: 16px;
  }

  .share-buttons {
    display: flex;
    flex-direction: row;
  }

  .share-button:hover {
    opacity: 1;
    color: #ffffff;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0px;
  }

  .pagination>span, .pagination>a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pagination i {
    padding: 6px;
  }

  .video {
    width: 100%;
    height: 180px;
    padding-bottom: 32px;
  }

  em {
    background-color: lightyellow;
  }
}

@include breakpoints(desktop) {
  .post,
  .blog {
    min-height: calc(100dvh - $spacing-unit * 10);
    max-width: 1100px;
    padding-top: 24px;

    .post-img {
      padding: 16px 16px;
      img {
        max-width: 100%;
        max-height: 60dvh;
      }
    }

    .video {
      width: 640px;
      height: 360px;
    }
  }

  
}
