.catalogue {
  display: flex;
  flex-direction: column;

  .item {  
    display: flex;
    flex-direction: column;
    margin: 25px auto;

    .img {
      height: auto;
      margin-bottom: auto;
    }
    .content {
       margin-top: 12px;
       margin-bottom: 24px;
      
      .button {
        background-color: #3DB76F;
        color: #ffffff;
        font-size: 13px;
        padding: 6px 24px;
        margin-top: 8px;
        display: inline-block;
        border-radius: 50px;
      }
    }
  }
}

@include breakpoints(tablet) {
    .catalogue {
        .item {
          flex-direction: row;
          margin: 25px auto;
    
          .img {
            width: 55%;
            padding: 10px;
            height: auto;
            margin-bottom: auto;
          }
        }

        .item:nth-of-type(even) {
            flex-direction: row-reverse;
          }
      }
}

@include breakpoints(desktop) {
  .catalogue {
    .item {
      flex-direction: row;
      margin: 25px auto;

      .img {
        width: 55%;
        padding: 10px;
        height: auto;
        margin-bottom: auto;
      }
    }

    .item:nth-of-type(even) {
        flex-direction: row-reverse;
      }
  }
}

.cp {
    .hero {
        margin-top: 24px;
        .img {

        }
        .content {
            margin-top: 24px;
            .title {
                font-size: 24px;
                font-weight: medium;
            }

            .subtitle {
                font-size: 16px;
            }
        }
    }
}

@include breakpoints(tablet) {
    .cp {
        .hero {
            display: flex;
            flex-direction: row;
            
            .img {
                width: 50%;
                padding: 24px;
            }

            .content {
                margin-top: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
            }
        }
    }
}

@include breakpoints(desktop) {
    .cp {
        .hero {
            display: flex;
            flex-direction: row;
            
            .img {
                width: 50%;
                padding: 24px;
            }
            .content {
                margin-top: 0px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;

                .title {
                    font-size: 36px;
                    font-weight: medium;
                }
    
                .subtitle {
                    font-size: 20px;
                }
            }
        }
    }
}