@charset "utf-8";

// Define defaults for each variable.

$base-font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     16px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #84e500 !default;
$on-brand-color:   #5a9e00;

$primary-main-color:  #1976d2;
$primary-light-color: #42a5f5;
$primary-dark-color:  #1565c0;
$on-primary-color:    #fff;

$grey-color:       #828282 !default;
$grey-color-light: #D6D6D6;
$grey-color-dark:  darken($grey-color, 25%) !default;
$grey-color-slate: #cbd5e1;

$table-text-align: left !default;

// Width of the content area
$content-width:    1200px !default;

$on-palm:          600px !default;
$on-laptop:        768px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Named Breakpoint Values
$breakpoints: (
  small: 320px,
  medium: 640px,
  large: 1024px,
  x-large: 1600px,
  xx-large: 2800px,
);

@mixin breakpoints($point) {

  $mobile: "(max-width: 600px)";
  $tablet: "(min-width: 601px) and (max-width: 900px)";
  $desktop: "(min-width: 901px)";
  
  @if $point == mobile {
        @media #{$mobile} { @content; }
      }
        @else if $point == tablet {
          @media #{$tablet} { @content; }
      }
        @else if $point == desktop {
          @media #{$desktop} { @content; }
      }
  }
  

// Import partials.
@import
  "minima/01_base",
  "minima/02_header",
  "minima/03_footer",
  "minima/11_home",
  "minima/12_catalogue",
  "minima/13_service",
  "minima/14_aboutus",
  "minima/15_contactus",
  "minima/16_blog",
  "minima/31_syntax-highlighting";
