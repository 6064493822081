.sp {
  .bg {
    background-color: #EBFAEE;
  }

  .hero {
    margin-bottom: 16px;
    text-align: center;
    
    .content {
      text-align: left;
    }
  }

  .intro-bg {
    background-color: #d9d9d9;
  }

  .intro p {
    padding: 48px 16px;
    text-align: center;
  }

  .approach {
    .item {
      display: flex;
      flex-direction: column;
      margin: 30px 0px;

      .content {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .points {
        border-radius: 16px;
        
        ul {
          background-color: #ffffff;
          padding: 24px 12px;
          border-radius: 16px;
          margin: 0px;
        }
      }

      li {
        background: url("/assets/checkbox.png") no-repeat left top;
        padding: 0px 0px 24px 30px;
        list-style: none;
        margin-left: 10px;
      }
    }
  }

  .method {
    
    .items {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        flex-direction: column;
        margin: 30px 0px;

        .content {
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }

  .whyus {
        .items {
            display: flex;
            flex-direction: column;

            .item {
                text-align: center;
                margin: 24px 0px;

                .img {
                       img {
                        background-color: #F6DE9D;  
                        padding: 16px;
                        border-radius: 50%;
                        width: 30px;
                       }
                }

                .content {
                    margin: 16px 24px;
                }
            }
        }
    }

    .get-started {
        margin: 30px auto;
    }
}

@include breakpoints(desktop) {
  .sp {
    .hero {
      padding: 0px;
      align-items: center;
      flex-direction: row-reverse;
  
      .img {
        width: 65vh;
      }

      .content {
        width: 50%;
      }
    }

    .intro p {
      padding: 60px;
    }

    .approach {
      .item {
        flex-direction: row;
        margin: 60px 0px;

        .content,
        .points {
          width: 50%;
        }

        .content {
          padding: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .points {
          background-color: #c5e0fd;
          border-radius: 16px;
          padding: 32px;
          ul {
            background-color: #ffffff;
            padding: 24px 12px 12px 12px;
            border-radius: 16px;
            margin: 0px;
          }
        }

        li {
          background: url("/assets/checkbox.png") no-repeat left top;
          padding: 0px 0px 24px 30px;
          list-style: none;
          margin-left: 10px;
        }
      }

      .item:nth-of-type(even) {
        flex-direction: row-reverse;
        .content {
          padding-left: 32px;
        }
      }
    }

    .method {
      .items {
        display: flex;
        flex-direction: row;

        .item {
          display: flex;
          flex-direction: column;
          margin: 30px 6px;
          padding: 16px;
          border-radius: 16px;
          background-color: #ccf4eb;
          width: 33%;

          img {
             height: 200px;
          }
          .content {
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }

    .whyus {
        .items {
            display: flex;
            flex-direction: row;
            text-align: left;

            .item {
                text-align: left;
                width: 33%;
                img {
                    margin-left: 24px;
                }
            }
        }
    }
  }
}
