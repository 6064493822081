h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5;
}

h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
}

h3 {
    font-size: 24px;
    line-height: 1.5;
}

h4 {
    font-size: 20px;
}

.center {
    text-align: center;
}

p {
    font-size: 16px;
    line-height: 1.5;
}

.small {
    font-size: 14px;
}

.xsmall {
    font-size: 12px;
}

@include breakpoints(desktop) {
    h1 {
        font-size: 60px;
        font-weight: 700;
        line-height: 1.5;
    }
    
    h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: 1.5;
    }
    
    h3 {
        font-size: 30px;
        line-height: 1.5;
    }
    
    h4 {
        font-size: 24px;
    }
    
    .center {
        text-align: center;
    }
    
    p {
        font-size: 16px;
        line-height: 1.5;
    }
    
    .small {
        font-size: 14px;
    }    
}

.hidden {
    display: none;
}

.hp {

    .bg {
        background-image: url(/assets/hero-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .hero {
        display: flex;
        flex-direction: column;
        
        .img {
            display: flex;
            flex-direction: row;
            justify-content: center;
            img {
                width: 70%;                
            }
        }
        
        .welcome {
            font-weight: bold;
            line-height: 1.5;
            color:#ffffff; // #1e5075;
          }
      
          .button {
            background-color: #3DB76F;
            display: block;
            width: 140px;
            color: #ffffff;
            padding: 12px;
            border-radius: 50px;
            text-align: center;
            margin-top: 36px;
            margin-bottom: 24px;
          }
    }

    .intro {
        text-align: center;
        margin: 50px auto;
    }
}

@include breakpoints(tablet) {
    .hp {

        .hero {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }
    }
}

@include breakpoints(desktop) {
    .hp {

        .hero {
            display: flex;
            flex-direction: row-reverse;
            
            align-items: center;
            
            .img {
                flex-basis: 1;
                min-width: 450px;
                img {
                    width: 100%;  
                    height: auto;              
                }
            }
        
              .button {
                width: 160px;
                margin-top: 60px;
              }
        }

    }
}