.cuc {
  margin-top: 24px;

  form {
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 8px;
    margin-bottom: 32px;

    .item {
      display: flex;
      flex-direction: column;
      padding: 6px;

      label {
        padding: 6px;
        font-size: 13px;
        text-align: left;
      }

      input,
      textarea {
        width: 100%;
        padding: 6px;
        border-radius: 3px;
        font-family: Poppins;
        outline-color: #3DB76F;
        border: 1px solid #d6d6d6;
      }

      input,textarea:focus {
        border: 1px solid #d6d6d6;
      }

      button {
        background-color: #3DB76F;
        border: 1px solid #3DB76F;
        color: #ffffff;
        padding: 12px 32px;
        border-radius: 50px;
        margin-top: 12px;
      }
    }

    .buttons {
      flex-direction: row;
      padding-bottom: 24px;
    }
  }
}

.cu {
  .bg {
      background-color: #ebfaee;
  }

  .hero {
    padding: 16px 0p 32px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img, .content {
      width: 100%;
    }
  }
}

.cuc-message {
  padding: 16px 0p 32px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img, .content {
      width: 100%;
    }
}

.container {
  padding: 6px;
  border-radius: 8px;
  margin-bottom: 32px;

  display: flex;
  background-color: #ffffff;
}

.contact-info {
  width:40%;
  padding: 15px;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
 }

 .info-item {
   display: flex;
   align-items: center;
   margin-bottom: 25px;
 }

 .info-item img {
   width: 24px;
   margin-right: 10px;
 }

 .info-item h5 {
   margin: 0;
 }

 @include breakpoints(tablet) {
   .container {
     flex-direction: column;
   }

   .contact-info {
     width: 100%;
   }

   .cu {
     .hero {
       flex-direction: row-reverse;

       .img,
       .content {
         width: 50%;
       }
     }
   }

   .cuc-message {
     flex-direction: row-reverse;

     .img,
     .content {
       width: 50%;
     }
   }
    
   .cuc {
      form {
         width: 93%;
      }
    }
 }

@include breakpoints(mobile) {
  .container {
    flex-direction: column;
  }

  .contact-info {
    width: 100%;
  }
  
  .cu {
    .hero {
      flex-direction: row-reverse;

      .img,
      .content {
        width: 50%;
      }
    }
  }
  
  .cuc-message {
    flex-direction: row-reverse;
    .img,
    .content {
      width: 50%;
    }
  }

  .cuc {
    form {
      width: 93%;
    }
  }
}

@include breakpoints(desktop) {
  .cu {
    .hero {
      flex-direction: row-reverse;

      .img,
      .content {
        width: 50%;
      }
    }
  }
    
  .cuc-message {
    flex-direction: row-reverse;

    .img,
    .content {
      width: 50%;
    }
  }

  .cuc {
    form {
      padding: 20px 24px;
      .item {
        button {
          margin-top: 12px;
        }
      }
    }
  }
}
