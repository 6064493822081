/**
 * Site header
 */

 .page-link {
   text-decoration: none !important;
 }

 .page-link:hover {
  font-size: large;
   font-weight: 600;
 }
 
.site-header {
  border-bottom: 3px solid #3DB76F;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  
  .header {
    display: flex;
    flex-direction: row;
    margin: auto -8px;
    align-items: center;
    min-height: $spacing-unit * 3.5;

    .logo {
      padding-left: auto;
      width: 100px;
    }

    .site_nav {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;
      
      ul {
        display: none;
      }

      label {
        display: block;
        margin: auto 16px;
      }
    }
  }

  .vertical-menu {
    height: calc(100dvh - $spacing-unit * 3.5);
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      li {
        font-size: 16px;
        margin: 16px 0px;
      }
    }
  }
}

@include breakpoints(desktop) {
  .site-header {

    .header {
      min-height: $spacing-unit * 5;

      .logo {
        width: 165px;
      }

      .site_nav {
        ul {
          display: block;
          list-style: none;
          display: flex;
          flex-direction: row;
        }

        li {
          margin: 16px 0px 16px 32px;
        }

       label {
          display: none;
        }
      }
    }
  }
}

@include breakpoints(tablet) {
  .site-header {
    

    .header {
      
      .logo {
        width: 100px;
      }

      .site_nav {
        ul {
          display: block;
          list-style: none;
          display: flex;
          flex-direction: row;
        }

        li {
          margin: 16px 0px 16px 32px;
        }

       label {
          display: none;
        }
      }
    }
  }
}

.page-section {
  min-height: calc(100dvh - $spacing-unit * 3.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include breakpoints(desktop) {
  .page-section {
    min-height: calc(100dvh - $spacing-unit * 5);
  }
}