.au {
  .bg {
    background-color: #EBFAEE;
  }

  .hero {
    padding: 16px 0p 32px 0px;
    display: flex;
    flex-direction: column;

    .img {
      width: 100%;
    }

    .content {
      .buttons {
        display: flex;
        flex-direction: row;
        margin: 24px 0px;

        .button {
          background-color: #3DB76F;
          display: block;
          width: 140px;
          color: #ffffff;
          padding: 12px;
          border-radius: 50px;
          text-align: center;
          margin-top: 36px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .intro {
    margin: 48px 12px;
    padding: 24px auto;
  }

  .mv {
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .item {
      text-align: center;
    }
  }

  .team {
    .members {
      display: flex;
      flex-direction: column;
      margin: 16px 0px;

      .item {
        margin: auto;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img, .content {
          text-align: center;
        }

        img {
          width: 300px;
        }

        .content {
          text-align: left;
        }
        
        .profile {
          display: inline-block;
          border-radius: 50%;
          width: 25%;
          border: 2px solid #3DB76F;
        }
      }
    }
  }
}

@include breakpoints(desktop) {
  .au {
    .hero {
      flex-direction: row-reverse;
      align-items: center;

      padding: 0px;
      .img,
      .content {
        width: 50%;
      }
      
      .content {
        .buttons {
          display: flex;
          flex-direction: row;

          .button {
            padding: 12px 16px;
            background-color: #3DB76F;
            margin-right: 16px;
            color: #ffffff;
            border-radius: 30px;
          }
        }
      }
    }

    .intro {
      margin: 60px auto;
    }

    .mv {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .item {
        width: 300px;
        text-align: center;
      }
    }

    .team {
      .members {
        display: flex;
        flex-direction: column;
        margin: 36px 0px;
  
        .item {
          margin: auto;
          padding: 24px 16px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .img, .content {
            width: 50%;
            text-align: center;
          }
  
          img {
            width: 300px;
          }
  
          .content {
            text-align: left;
            padding-left: 16px;
          }
        }
  
        .bg:nth-of-type(even)>.item {
          flex-direction: row;
        }
      }
    }

  }
}
