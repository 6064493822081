
/**
 * Site footer
 */

 footer {
    font-size: 12px;
    text-align: center;
    border-top: 1px solid #D6D6D6; //Light Grey
    padding: 16px 0 12px 0;
    background-color: #2C2C2B; // Dark Grey
    color:#FFFFFF;             // White 
  }